import { SearchPage } from 'Components'
import TemplateMainContent from 'Stores/wgl/Templates/MainContent.rt'
import { SearchBoxDialogButton } from 'Components'
import TemplatesearchBoxdialogButtonAdaptable from 'Stores/_default-store/Templates/searchBox/dialogButtonAdaptable.rt'
import { SearchHeader } from 'Components'
import { FacetPanel } from 'Components'
import TemplateFacetPanelContainer from 'Stores/_default-store/Templates/FacetPanelContainer.rt'
import { SearchResult } from 'Components'
import { FacetDialog } from 'Components'
import TemplateFacetDialog from 'Stores/_default-store/Templates/FacetDialog.rt'
import { SearchBoxDialog } from 'Components'
import TemplateSearchBoxDialog from 'Stores/_default-store/Templates/SearchBoxDialog.rt'

export const compMap = {
  SearchPage,
SearchBoxDialogButton,
SearchHeader,
FacetPanel,
SearchResult,
FacetDialog,
SearchBoxDialog
} 
export const tplMap = {
  'MainContent': TemplateMainContent,
'searchBox/dialogButtonAdaptable': TemplatesearchBoxdialogButtonAdaptable,
'FacetPanelContainer': TemplateFacetPanelContainer,
'FacetDialog': TemplateFacetDialog,
'SearchBoxDialog': TemplateSearchBoxDialog
};