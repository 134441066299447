const pageNumberField = 'page';

const serviceFieldsNamesMap = {
  sort: 'sort',
  showed: 'pageSize',
  query: 'query',
};

const selectionFieldsNamesMap = {
  CurrentPrice: 'Price',
  ManufacturerName: 'Manufacturer',
};

export default {
  parsingNeeded() {
    const { search: locationSearch, hash: locationHash } = window.location;

    return !!locationHash;
  },
  parse() {
    const { search: locationSearch, hash: locationHash } = window.location;

    let pageNumber;
    const serviceFields = {};
    const selection = [];
    const facets = {};

    locationHash
      .replace('#', '')
      .split('&')
      .forEach((param) => {
        const [field, value] = param.split('=');

        // https://www.wegotlites.com/search.asp#query=lamp&showed=48&sort=OnSale:desc
        if (serviceFieldsNamesMap[field]) {
          serviceFields[serviceFieldsNamesMap[field]] = value;
        }

        // https://www.wegotlites.com/search.asp#query=lamp&page=2
        // https://www.wegotlites.com/search.asp#query=lamp&page=-1 //values < 0 omitted
        if (field === pageNumberField && parseInt(value) >= 0) {
          pageNumber = parseInt(value);
        }

        // Drop Disp* duplicates of fld and sel, depending on schema we could choose to drop non-Disp* instead, but they might appear to be insufficient
        // https://www.wegotlites.com/search.asp#query=&showed=20&page=0&fld0=CategoryIDs&sel0=56&fldDisp0=Category&selDisp0=Chandeliers&fld1=OnSale&sel1=Yes
        if (field.includes('Disp')) {
          return;
        }

        // Key part of key-value pair of facet selection
        // https://www.wegotlites.com/search.asp#fld0=CategoryIDs&sel0=56&fldDisp0=Category&selDisp0=Chandeliers&fld1=OnSale&sel1=Yes
        if (field.startsWith('fld')) {
          const filedNum = field.substring(3);
          if (!facets[filedNum]) {
            facets[filedNum] = {};
          }
          facets[filedNum].Key = value;
        }

        // See prev. example, this is value part of key-value pair of facet selection
        if (field.startsWith('sel')) {
          const filedNum = field.substring(3);
          if (!facets[filedNum]) {
            facets[filedNum] = {};
          }
          facets[filedNum].Value = value;
        }
      });

    for (const v of Object.values(facets)) {
      if (v.Key && v.Value) {
        // Rename selection fields
        let preprocessedKey = v.Key;
        if (Object.keys(selectionFieldsNamesMap).includes(preprocessedKey.trim())) {
          preprocessedKey = preprocessedKey.replace(
            preprocessedKey.trim(),
            selectionFieldsNamesMap[preprocessedKey.trim()],
          );
        }

        // Convert ranges
        const preprocessedValue = decodeURI(v.Value).replace(/\[([\d.]+)\s*TO\s*([\d.]+)\]/, '$1 TO $2');

        // Convert Bool value stored as string
        if (preprocessedKey === 'OnSale') {
          selection.push({ field: preprocessedKey, term: preprocessedValue === 'Yes' ? 'True' : 'False' });
        } else {
          selection.push({ field: preprocessedKey, term: preprocessedValue });
        }
      }
    }

    // use JSON.parse(JSON.stringify()) to filter undefined
    return JSON.parse(JSON.stringify({ selection, ...serviceFields, pageNumber }));
  },
};
